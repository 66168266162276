import { Link } from 'gatsby';
import React from 'react';
import CookieConsent from 'react-cookie-consent';
import Helmet from 'react-helmet';
import Footer from '../footer/footer';
import Navbar from '../navbar/navbar';
import Styles from './layout.module.scss';

// Helmet does not support React.Fragment, therefore the below syntax
// see: https://github.com/nfl/react-helmet/issues/342
const Meta = ({ title, description, image }) => (
    <Helmet>
        {title && <title>{title}</title>}
        {title && <meta name="og:title" content={title}></meta>}
        {description && <meta name="description" content={description}></meta>}
        {description && <meta name="og:description" content={description}></meta>}
        {image && <meta name="image" content={image}></meta>}
        {image && <meta name="og:image" content={image}></meta>}
        <script data-ad-client="ca-pub-9232022234598092" async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js"></script>
    </Helmet>
);

export default ({ footerPosts, children, title, description, image, manualTitleControl }) => {
    let titleSuffix = 'Mindfoe';
    let titleDivider = ' - ';
    if (manualTitleControl) {
        titleSuffix = '';
        titleDivider = '';
    }
    return (
        <React.Fragment>
            <Meta
                title={`${(title === null || title === undefined) ? '' : title + titleDivider}${titleSuffix}`}
                description={description}
                image={image}
            />
            <Navbar/>
            {children}
            <Footer posts={footerPosts} maxPosts={3}/>
            <CookieConsent
                location="bottom"
                buttonText="Okay"
                cookieName="mindfoe-cookie-consent"
                buttonClasses={`${Styles.cookieButton} text-white bg-pink`}
                containerClasses=""
                style={{ background: '#252422' }}
                expires={150}
            >
                Diese Website verwendet Cookies.{" "}<br className="d-sm-none"></br>
                <Link to="/datenschutz">Mehr erfahren.</Link>
            </CookieConsent>
        </React.Fragment>
    );
}
