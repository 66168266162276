import { Link } from 'gatsby';
import React, { useEffect } from 'react';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { FacebookIcon, InstagramIcon, YoutubeIcon } from '../icons/icons';
import Styles from './navbar.module.scss';

const navbarID = 'js-nav';
const shadowClass = 'shadow-sm';

const handleScroll = () => {
    const navbar = document.getElementById(navbarID);
    if (window.scrollY > 60) {
        navbar.classList.add(shadowClass);
    } else {
        navbar.classList.remove(shadowClass);
    }
};

const navID = 'responsive-navbar-nav';
const toggleNav = () => {
    document.getElementById(navbarID).classList.toggle(Styles.navbar);
    document.getElementById(navbarID).classList.toggle(shadowClass);
    document.getElementById(navID).classList.toggle('show');
    document.getElementById(navID).classList.toggle(Styles.mobileNav);
};

const socialIconSize = 20;

export default () => {
    
    useEffect(() => {
        document.addEventListener('scroll', handleScroll);
        handleScroll();

        return () => {
            document.removeEventListener('scroll', handleScroll);
        }
    });

    return (
        <Navbar id="js-nav" fixed="top" collapseOnSelect expand="lg" bg="white" className="d-flex flex-column">
            <Container>
                <Navbar.Brand>
                    <Link to="/">
                        <img
                            src="/logo/mindfoe-brain-long.svg"
                            className={`d-inline-block align-top ${Styles.logo}`}
                            alt="Mindfoe Logo"
                        />
                    </Link>
                </Navbar.Brand>

                <button onClick={toggleNav} aria-controls="responsive-navbar-nav" type="button" aria-label="Toggle navigation" className={`navbar-toggler ${Styles.toggler}`}>
                    <span className="navbar-toggler-icon"></span>
                </button>

                <Navbar.Collapse id="responsive-navbar-nav">
                    <Nav className="ml-auto">
                        <Link className={`nav-link ${Styles.navLink}`} activeClassName={Styles.active} to="/">Home</Link>
                        <Link className={`nav-link ${Styles.navLink}`} activeClassName={Styles.active} to="/about">About</Link>
                        <Link className={`nav-link ${Styles.navLink}`} activeClassName={Styles.active} partiallyActive={true} to="/blog">Blog</Link>
                        <Link className={`nav-link ${Styles.navLink}`} activeClassName={Styles.active} to="/kontakt">Kontakt</Link>
                        <div className="d-lg-none">
                            <FacebookIcon size={socialIconSize} linkClass={`${Styles.navLinkIcon}`}/>
                            <InstagramIcon size={socialIconSize} linkClass={`${Styles.navLinkIcon}`}/>
                            <YoutubeIcon size={socialIconSize} linkClass={`${Styles.navLinkIcon}`}/>
                        </div>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
}
